import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import './index.css'; // Assuming you're using an external CSS file

// Lazy load the App component
const App = lazy(() => import("./App"));
const queryClient = new QueryClient();

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-logo">
        {/* You can add your logo image here */}
        <img src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/BlackBrick-Black.png" alt="BlackBrick Property" />
      </div>
      <div className="loading-message">
        <h2>Welcome to BlackBrick Property</h2>
        <p>Your trusted real estate partner. We are loading the best listings for you...</p>
      </div>
      <div className="loading-spinner">
        <div className="spinner"></div> {/* Add a spinner animation */}
      </div>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
   <Suspense fallback={<LoadingScreen />}>
      <App />
    </Suspense>
  </QueryClientProvider>
);